// ListSection.js
import React from 'react';

const ListSection = ({ children, multiSelect=false, isRequired=false, sectionClass }) => {
  return (
    <section className={`${sectionClass && `section--${sectionClass}`} section-list borders__rounded section__fill-remainder`}>
        <ul className='section-list-wrapper secondary-bcg borders__rounded'>
            {children}
        </ul>
    </section>
  );
};

export default ListSection;
