import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { PAYMENT_VENDOR_KEY as stripeKey } from '../../environmentSetup.js';
import { paymentURL } from '../../api/urls.js';

console.log('--------------------');
console.log(typeof process.env.REACT_APP_STRIPE_TOKEN);
console.log('--------------------');
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_TOKEN}`);
// const stripePromise = loadStripe(stripeKey);

const StripeIntegration = (userSelections) => {
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    // const priceData = { unit_amount: 17500 };
    const fetchData = async () => {
      try {
        const res = await fetch(`${paymentURL}/create-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userSelections),
        });
        const data = await res.json();
        setClientSecret(data.clientSecret);
      } catch (error) {
        console.error('Failed to fetch client secret:', error);
      }
    };

    fetchData();
  }, [paymentURL]);

  return (
    <div>
      <div>OUR BRANDING HERE</div>
      <div id="deposit-container">
        {clientSecret && (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
      <div>MORE BRANDING HERE</div>
    </div>
  );
};

export default StripeIntegration;
