/**
 * This file serves as an intermediary between route setting and page styling/rendering.
 * It contains functions to render pages based on options logic.
 */

import { useNavigate } from 'react-router-dom';
import DoubleSelector from '../../templates/OptionSelectionPages/TwoOptionSelection';
import SingleSelector from '../../templates/OptionSelectionPages/OneOptionSelection';
import { openOptionsParcel } from '../../utils/parsers/questionaireParser';

/**
 * Renders a DoubleSelector component.
 * This function is called when there are two options to be displayed.
 *
 * @param {Array} optionsParcel - An array containing two sets of options.
 * @param {Object} [{ navigate = null }={}] - Object containing the 'navigate' function for navigation. Default is null.
 * @returns {JSX.Element} - A JSX element of the DoubleSelector component.
 */
const getDoubleSelectorPage = (optionsParcel, { navigate = null } = {}) => {
  const options = openOptionsParcel(optionsParcel);
  
  return (
    <DoubleSelector options={options} executeNavigation={navigate} />
  );
};

/**
 * Renders a SingleSelector component.
 * This function is called when there is only one option to be displayed.
 *
 * @param {Object} optionsParcel - An object containing the single set of options.
 * @param {Object} [{ navigate = null }={}] - Object containing the 'navigate' function for navigation. Default is null.
 * @returns {JSX.Element} - A JSX element of the SingleSelector component.
 */
const getSingleSelectorPage = (optionsParcel, { navigate = null } = {}) => {
  const option = openOptionsParcel(optionsParcel);
  
  return (
    <SingleSelector option={option} executeNavigation={navigate} />
  );
};

/**
 * Component for rendering the correct option selector based on the provided options.
 * This component decides to render either SingleSelector or DoubleSelector 
 * based on the number of options provided in the optionsContainer.
 *
 * @param {Array|Object} optionsContainer - Array or Object containing the set(s) of options.
 * @param {string} linksTo - The path to navigate to upon executing navigation.
 * @returns {JSX.Element} - A JSX element of either SingleSelector or DoubleSelector component.
 * @throws {Error} - Throws an error if optionsContainer is undefined or if the number of options is neither 1 nor 2.
 */
const OptionSelectorPage = ({ optionsParcel, linksTo, onSubmit, exitPath }) => {
  const navigate = useNavigate()
  
  const handleSubmission = () => {
    if (linksTo === exitPath) {
      navigate(exitPath);
    } else {
      onSubmit(linksTo);
    }
  }
  
  // Conditional rendering based on the number of options
  if (optionsParcel.length === 2) {
    return getDoubleSelectorPage(optionsParcel, { navigate: handleSubmission });
  } else if (optionsParcel.length === 1) {
    return getSingleSelectorPage(optionsParcel[0], { navigate: handleSubmission });
  } else if (!optionsParcel) {
    throw new Error("undefined options");
  } else {
    throw new Error("At most 2 options can share a single path");
  }      
};

export default OptionSelectorPage;
