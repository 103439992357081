import React, { useState } from 'react';
import { SearchBar } from './searchBar.jsx';
import { SearchResults } from './searchResults.jsx';
export const SearchBarContainer = ({
  selected,
  setSelected,
  results,
  setResults,
  setAddress,
  setErrors,
  hasError,
}) => {
  const [text, setText] = useState('');

  const [display, setDisplay] = useState(false);
  return (
    <div className="relative-position">
      <SearchBar
        results={results}
        setResults={setResults}
        text={text}
        setText={setText}
        setSelected={setSelected}
        selected={selected}
        setErrors={setErrors}
        hasError={hasError}
        setDisplay={setDisplay}
        displayResults={display}
        setAddress={setAddress}
      ></SearchBar>
      {results !== '' && text !== '' && text.length < 10 && (
        <SearchResults
          results={results}
          setText={setText}
          setResults={setResults}
          setSelected={setSelected}
          setAddress={setAddress}
          setErrors={setErrors}
          display={display}
        ></SearchResults>
      )}
    </div>
  );
};
