import React from 'react';
import { ReactComponent as ExitButton } from '../../assets/icons/exit-button.svg';
const PriceBreakdown = ({
  massageType,
  massageDuration,
  addons,
  showPriceBreakdown,
  setShowPriceBreakdown,
}) => {
  // console.log('boolean', showPriceBreakdown);
  let priceBreakdownAnimation = '';

  let displayNoneCSS = 'opacity-zero';
  if (showPriceBreakdown) {
    priceBreakdownAnimation = 'price-modal-transformation';
    displayNoneCSS = ' ';
  }
  let price = massageType.price + massageDuration.price;
  addons.forEach((x) => (price += x.price));

  // console.log('this is addons', addons[0]);
  let addOns = addons.map((addon) => {
    return (
      <ListItem
        key={addon.id}
        first={addon.id.replace('_', ' ')}
        second={`$${addon.price}`}
      ></ListItem>
    );
  });
  return (
    <div
      id="price-breakdown"
      className={`${priceBreakdownAnimation} ${displayNoneCSS}  flex-column-direction flex-column-container-gap secondary-bcg container-top-radius container-padding`}
    >
      <div className="flex-row-direction flex-row-center">
        <h6>Price Breakdown</h6>
        <ExitButton onClick={(e) => setShowPriceBreakdown(false)}></ExitButton>
      </div>
      <hr className="clear-border flex-column-self-center " />

      <ListItem
        first={`${massageType.title} Massage`}
        second={`$${massageType.price}`}
      ></ListItem>
      <ListItem
        first={`${massageDuration.title} Duration`}
        second={`$${massageDuration.price}`}
      ></ListItem>
      {addOns.length > 0 && (
        <>
          <h6 className="text-align-center">Add-ons:</h6>
          {addOns}
        </>
      )}
      <hr className="clear-border flex-column-self-center " />
      <ListItem first="Total" second={`$${price}`}></ListItem>
    </div>
  );
};
const ListItem = ({ first, second }) => {
  let bold;
  if (first === 'Total') {
    bold = 'bold';
  }
  return (
    <div className="flex-row-direction flex-space-between ">
      <p className={`${bold} normal-text-light capitalize`}>{first}</p>
      <p className={`${bold} normal-text-light`}>{second}</p>
    </div>
  );
};
export default PriceBreakdown;
