import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BackArrow } from '../../assets/icons/Back.svg';

/**
 * Makes a Basic NavBar
 * @param {Function} callback function used to go previous page
 * @param {Boolean} hasPrevious boolean used to add back arrow
 * @returns
 */
const NavBar = ({ callback, hasPrevious }) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }
  
  return (
    <nav className="container-padding flex-row-direction">
      <BackArrow
        onClick={() => {
          callback ? callback() : goBack();
        }}
      ></BackArrow>
      <h2 className="secondary-clr">Empowered Movement Therapy</h2>
    </nav>
  );
};

export default NavBar;
