export const reqDaysOffObject = [
  { reqDayOff: '2024-02-28' },
  { reqDayOff: '2024-02-29' },
  { reqDayOff: '2024-03-01' },
  { reqDayOff: '2024-03-02' },
  { reqDayOff: '2024-03-03' },
  { reqDayOff: '2024-03-04' },
  { reqDayOff: '2024-03-05' },
  { reqDayOff: '2024-03-06' },
  { reqDayOff: '2024-03-28' },
];
