import { pstdayjs } from '../../utils/calendar/calendarTools.js';

function CalendarGrid({
  fillerDays,
  currentMonthDays,
  callback,
  selectedDay,
  therapist,
  calMan,
}) {
  let dayNamesArray = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  let { prevMonthDays, nextMonthDays } = fillerDays;

  let gridData = dayNamesArray.concat(
    prevMonthDays,
    currentMonthDays,
    nextMonthDays
  );

  // Finding which days are part of the current month
  let lastDayOfCurrentMonth = currentMonthDays[currentMonthDays.length - 1];
  let firstDayOfCurrentMonthIndex = gridData.indexOf(1);
  let lastDayOfCurrentMonthIndex = gridData.lastIndexOf(lastDayOfCurrentMonth);

  let copyDate = pstdayjs(calMan.viewingDate);
  return (
    <div className="calendar-grid">
      {gridData.map((day, i) => {
        // Name of day of week
        if (i < 7) {
          return (
            <p key={i} className="normal-text-small-bold">
              {dayNamesArray[i]}
            </p>
          );
        } else if (
          // looking at current month
          i >= firstDayOfCurrentMonthIndex &&
          i <= lastDayOfCurrentMonthIndex
        ) {
          let isSelectedClass = day == selectedDay ? 'selected-day' : '';

          let movingDate = pstdayjs(copyDate).set('date', day);
          let canWorkToday = therapist.checkIfCanWorkThisDay(movingDate);
          if (canWorkToday) {
            return (
              <p
                key={i}
                className={
                  'current-month-day normal-text-big bold ' + isSelectedClass
                }
                onClick={() => {
                  callback(day);
                }}
              >
                {day}
              </p>
            );
          } else {
            return (
              <p key={i} className="unavailable normal-text-big ">
                {day}
              </p>
            );
          }
        } else {
          return (
            <p key={i} className="unavailable normal-text-big ">
              {day}
            </p>
          );
        }
      })}
    </div>
  );
}

export default CalendarGrid;
