/**
 * Failed - The payment attempt was unsuccessful, possibly due to insufficient funds, incorrect card details, or other issues.
 * When a payment fails, the order is marked as "Not Completed" or "Declined" and the amount is not transferred to the
 * merchant's account. Failed transactions appear in Stripe's Failed payments report.
 *
 * Incomplete - The payment process was started but not completed. This could happen if the customer abandons the checkout
 * session or doesn't select Stripe as the payment method.
 */

import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import {
  createAppointment,
  createNewClientAPI,
  isAppointmentTimeOpen,
} from '../api/fetchRequests/appointmentRequests.js';
import {
  getAllUserSelections,
  saveSensitiveUserData,
} from '../utils/persistence/dataPersistence.js';
import { paymentURL } from '../api/urls.js';
import { mapAppointmentLength } from '../utils/tools/tools.js';
import { ReactComponent as CheckMark } from '../assets/icons/check-mark.svg';
import { ReactComponent as AlertIcon } from '../assets/icons/alert-circle-outline.svg';
import { useNavigate } from 'react-router-dom';
import { sendReceipt } from '../api/fetchRequests/email.js';
import { THERAPIST_ID } from '../environmentSetup.js';
const ConfirmationRouter = ({ nextPath }) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(nextPath);
  };
  const [status, setStatus] = useState(null);
  const [customerData, setCustomerData] = useState('');
  const [billingDetails, setBillingDetails] = useState('');
  const [responseReceived, setResponseReceived] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    // First Check if the appointment can be made
    let getAppointmentAvailability = async () => {
      let selections = getAllUserSelections();
      let appointmentAvailability;
      try {
        let appointmentDuration = await mapAppointmentLength(
          selections.questionnaire_selections
        );
        appointmentAvailability = await isAppointmentTimeOpen(
          selections.date,
          appointmentDuration,
          THERAPIST_ID
        );
      } catch (error) {
        setError(true);
        setStatus('complete');
        setResponseReceived(true);
      }

      if (appointmentAvailability.error) {
        // TODO
        // prompt the user to select another date
        setError(true);
        setStatus('complete');
        setResponseReceived(true);
        return;
      }

      //proceed to the next code

      let stripeObject;
      try {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');
        let stripeResponse = await fetch(
          `${paymentURL}/session-status?session_id=${sessionId}`
        );
        stripeObject = await stripeResponse.json();
        setStatus(stripeObject.status);
        setCustomerData(stripeObject.customer_details);
        setBillingDetails(stripeObject.billing_details);
      } catch (error) {
        // Handle Stripe Error Here
        setError(true);
        setResponseReceived(true);
        return;
      } finally {
        // This will make the page load
        // Probably not the best but right now we are waiting
        // for a stripe response to say if the payment
        // was successful or not
        setResponseReceived(true);
      }

      // Probably check if Stripe sent a good Response here
      // And return if it is bad or continue if response is good (paid)

      let stripeClientData = stripeObject.customer_details;
      let stripeBillingDetails = stripeObject.billing_details;
      let newClientData;
      let address;
      try {
        address = JSON.parse(selections.userAddress);
        newClientData = await createNewClientAPI(
          stripeClientData.name,
          stripeClientData.email,
          stripeClientData.phone,
          address.zip,
          address.addressLine1,
          address.city
        );
      } catch (error) {
        setError(true);
      }
      console.log('stripe billing details', stripeBillingDetails);
      try {
        // sendReceipt({
        //   ...stripeClientData,
        //   ...selections,
        //   appointmentLocation: address,
        //   billing_details: stripeBillingDetails,
        // });
        createAppointment(newClientData[0].client_id, THERAPIST_ID);
      } catch (error) {
        setError(true);
      }
    };

    // Check if we made a client successfully if not then return
    // else proceed

    getAppointmentAvailability();
    // const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);
    // const sessionId = urlParams.get('session_id');
    // if (status == null) {
    //   fetch(`${paymentURL}/session-status?session_id=${sessionId}`)
    //     .then((res) => res.json())
    //     .then((data) => {
    //       setStatus(data.status);
    //       setCustomerData(data.customer_details);
    //       setBillingDetails(data.billing_details);
    //     });
    // } else if (status === 'complete') {
    //   // API call to make appointment and new client
    //   let selections = getAllUserSelections();
    //   let address = JSON.parse(selections.userAddress);
    //   // TODO it doesn't make sense to have isAppointmentTimeOpen
    //   // here because the customer has already paid
    //   mapAppointmentLength(selections.questionnaire_selections)
    //     .then((duration) => {
    //       return isAppointmentTimeOpen(selections.date, duration, 1);
    //     })
    //     .then((data) => {
    //       if (data.status === 400) throw new Error('Cant make appointment');
    //       return;
    //     })
    //     .then(() => {
    //       return createNewClientAPI(
    //         customerData.name,
    //         customerData.email,
    //         customerData.phone,
    //         address.zip,
    //         address.addressLine1,
    //         address.city
    //       );
    //     })
    //     .then((data) => {
    //       return data.json();
    //     })
    //     .then((data) => {
    //       // selections Contains the appointment location
    //       // console.log('customerData', customerData);
    //       // console.log('selections', selections);
    //       sendReceipt({
    //         ...customerData,
    //         ...selections,
    //         appointmentLocation: address,
    //         billing_details: billingDetails,
    //         // questionnaire_selections: selections.questionnaire_selections,
    //       });
    //       createAppointment(data[0].client_id, 1);
    //     })
    //     .catch(() => {
    //       setError(true);
    //     })
    //     .finally(() => {
    //       setResponseReceived(true);
    //     });
    // }
  }, []);

  if (status === 'open') {
    return <Navigate to={`/`} />;
  }

  console.log('status:', status);
  console.log('responseReceived', responseReceived);
  if (status === 'complete') {
    if (!responseReceived) {
      return <p>Creating appointment...</p>;
    } else {
      return (
        <div className="secondary-bcg">
          {!error ? (
            <section
              id="success"
              className="gradient-bcg flex-column-direction height-100 flex-column-align-items-center"
            >
              <CheckMark className="flex-column-self-center bottom-margin "></CheckMark>
              <p className="text-align-center semi-bold bottom-margin width-75 flex-column-self-center">
                Your appointment was made successfully! An email confirmation
                was sent to {customerData.email}.
                <br className="bottom-margin"></br> If you have any questions,
                please email{' '}
                <a href="mailto:orders@example.com">orders@example.com</a>.
              </p>
              <button
                onClick={handleNavigation}
                className=" finish-button width-50  accent-bcg clear-border flex-column-self-center large-text white-color semi-bold"
              >
                {' '}
                Home
              </button>
            </section>
          ) : (
            <section
              id="error"
              className="error-gradient-bcg flex-column-direction height-100 flex-column-align-items-center"
            >
              <AlertIcon className="flex-column-self-center bottom-margin "></AlertIcon>
              <p className="text-align-center semi-bold bottom-margin width-75 flex-column-self-center">
                error creating appointment contact this number for further help
              </p>
            </section>
          )}
        </div>
      );
    }
  } else {
    return (
      <div>
        <div>Unable to process payment. Try again?</div>
        <button>Retry</button>
      </div>
    );
  }
};

export default ConfirmationRouter;
