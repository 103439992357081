import React from 'react';

const LocationTextField = ({ placeHolder, value, onChange, hasError }) => {
  let redBorder = '';
  let textColor = 'text-gray';
  if (hasError) {
    redBorder = 'error-border';
    textColor = 'text-clr-error';
  }
  return (
    <div className="animated-textfield-container">
      <input
        id={placeHolder}
        type="text"
        placeholder=" "
        className={` ${redBorder} container-padding  normal-text-big clear-border container-radius animated-textfield-input `}
        autoComplete="new-password"
        value={value}
        onFocus={focus}
        onChange={(e) => {
          onChange(e, placeHolder);
        }}
      />
      <label
        htmlFor={placeHolder}
        className={`${textColor} animated-textfield-label capitalize`}
      >
        {placeHolder}
      </label>
      {hasError && (
        <p className="capitalize text-clr-error normal-text-light">
          *{placeHolder} required
        </p>
      )}
    </div>
  );
};
const focus = (e) => {
  e.currentTarget.classList.toggle('focus');
};
export default LocationTextField;
