import React, { useState } from 'react';
import { SearchBarContainer } from '../addressSearchBar/searchBarContainer.jsx';
import NavigationButton from '../common/NavigationButton.jsx';
import LocationTextField from './LocationTextField.jsx';

const AddressForm = ({
  saveSensitiveData,

  handleNavigation,
}) => {
  const [address, setAddress] = useState({
    country: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    zip: '',
    state: '',
  });
  const [autocompleteSelected, setAutocompleteSelected] = useState(false);
  const [errors, setErrors] = useState({});
  const [results, setResults] = useState([]);

  const handleChange = (e, locationType) => {
    const { value } = e.target;
    e.currentTarget.classList.remove('text-clr-error', 'error-border');

    e.currentTarget.classList.remove('error-border');
    e.currentTarget.nextSibling.classList.remove('text-clr-error');
    setErrors((prevState) => ({
      ...prevState,
      [locationType]: undefined,
    }));
    setAddress((prevState) => ({
      ...prevState,
      [locationType]: value,
    }));
  };

  const handleValidation = () => {
    let tempErrors = {};
    let isValid = true;

    if (!address.addressLine1) {
      tempErrors['addressLine1'] = 'Address Line 1 is required';
      isValid = false;
    }
    if (!address.city) {
      tempErrors['city'] = 'City is required';
      isValid = false;
    }
    if (!address.zip) {
      tempErrors['zip'] = 'Zip Code is required';
      isValid = false;
    } else if (!/^\d{5}$/.test(address.zip)) {
      tempErrors['zip'] = 'Zip Code should be 5 digits';
      isValid = false;
    }
    if (!address.state) {
      tempErrors['state'] = 'State is required';
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      saveSensitiveData(JSON.stringify(address), 'userAddress');
      setAutocompleteSelected(true);

      handleNavigation();
    } else {
      setAutocompleteSelected(false);
    }
  };

  let additionalNotesText = 'Gate codes, Apartment name, Apartment floor';
  return (
    <form
      className="page-width page-layout__stacked"
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <div
        className="secondary-bcg
      container-padding  container-radius flex-column-direction section__fill-remainder"
      >
        <section className="flex-column-direction flex-column-container-gap">
          <SearchBarContainer
            selected={autocompleteSelected}
            setSelected={setAutocompleteSelected}
            results={results}
            setResults={setResults}
            setAddress={setAddress}
            setErrors={setErrors}
            hasError={errors.addressLine1 !== undefined}
          ></SearchBarContainer>

          <LocationTextField
            placeHolder="Apt #"
            value={''}
            onChange={handleChange}
            hasError={false}
          ></LocationTextField>
          <div className="lower-address">
            <LocationTextField
              onChange={handleChange}
              hasError={errors.city !== undefined}
              placeHolder="city"
              value={address.city}
            />
            <LocationTextField
              onChange={handleChange}
              hasError={errors.state !== undefined}
              placeHolder="state"
              value={address.state}
            />
            <LocationTextField
              onChange={handleChange}
              hasError={errors.zip !== undefined}
              placeHolder="zip"
              value={address.zip}
            />
          </div>
        </section>

        <div id="additional-notes-container">
          <h3 className="content-padding">
            Additional Notes
            <span className="normal-text-small text-gray">
              {' '}
              <i>(Optional)</i>
            </span>
          </h3>
          <textarea
            className="container-padding container-radius clear-border normal-text-big text-gray "
            placeholder={additionalNotesText}
          ></textarea>
        </div>
      </div>

      <section className="section-nav text-align-center split-item__stubborn">
        <NavigationButton description="Submit"></NavigationButton>
      </section>
    </form>
  );
};

export default AddressForm;
