import { pstdayjs } from '../../utils/calendar/calendarTools.js';
import Schedule from './Schedule.js';
/**
 * The point of this class is to return
 * which days are available and not available
 * given many schedules
 */
export default class ScheduleManager {
  constructor() {
    this.map = new Map(); //keys === "YYYY-MM-DD YYYY-MM-DD"
    this.LENGTH_OF_TIME_SLOTS = 30;
  }

  /**
   *
   * @param {Object} scheduleAvailability
   * @param {Object} scheduleBlockedDates
   * @param {Object} scheduleBlockedTimes
   */
  addSchedule(scheduleAvailability, scheduleBlockedDates) {
    let schedule = new Schedule(
      scheduleAvailability,
      scheduleBlockedDates,
      this.LENGTH_OF_TIME_SLOTS
    );
    this.map.set(`${schedule.startDate} ${schedule.endDate}`, schedule);
  }
  /**
   *
   * @param {Dayjs} date
   * @returns {Schedule}
   */
  #getScheduleWithDate(date) {
    let keys = Array.from(this.map.keys());
    let schedule = null;
    keys.forEach((key) => {
      let split = key.split(' ');
      let startDate = pstdayjs(split[0]).startOf('D');
      let endDate = pstdayjs(split[1]).endOf('D');

      if (date.isBetween(startDate, endDate, 'day', '[]'))
        schedule = this.map.get(key);
    });

    return schedule;
  }

  getOpenTimesForDay(date, appointmentTimeLength) {
    let schedule = this.#getScheduleWithDate(date);
    if (schedule === null) return [];

    return schedule.getOpenTimesForDay(date, appointmentTimeLength);
  }
  getSoonestAvailableWorkday(date) {
    // I should look through all schedules not just one
    // Example Schedule A,B,C . If A & B are filled then C
    // should have the next available schedules.
    // TODO: TEST BELOW
    let min = 500;
    let keys = Array.from(this.map.keys());

    keys.forEach((key) => {
      let value = this.map.get(key).getSoonestAvailableWorkday(date);
      if (value < min && value !== -1) min = value;
    });

    if (min === 500) return -1;

    return min;
  }
  checkIfCanWorkThisDay(date) {
    let schedule = this.#getScheduleWithDate(date);
    if (schedule === null) return false;

    return schedule.checkIfCanWorkThisDay(date);
  }
  /**
   * Maps appointments to a schedule. Assumes the appointments
   * are filtered by therapists ID already.
   * @param {Object[]} appointments
   */
  mapBookedAppointmentsToSchedules(appointments) {
    let appointmentMap = new Map();

    // Aggregating all appointments to arrays that
    // where an array represents a time range
    appointments.forEach((appointment) => {
      let schedule = this.#getScheduleWithDate(
        pstdayjs(appointment.appointmentTime)
      );
      if (!schedule) return;
      // Do have a schedule
      if (appointmentMap.has(`${schedule.startDate} ${schedule.endDate}`))
        appointmentMap
          .get(`${schedule.startDate} ${schedule.endDate}`)
          .push(appointment);
      else {
        appointmentMap.set(`${schedule.startDate} ${schedule.endDate}`, [
          appointment,
        ]);
      }
    });
    let keys = Array.from(appointmentMap.keys());

    // Key should be format = "2024-11-20 2024-11-30"
    keys.forEach((key) => {
      let schedule = this.map.get(key);
      schedule.mapAppointments(appointmentMap.get(key));
    });
  }
}
