import ButtonGrid from './ButtonGrid';
import DropDown from './DropDown';

function TimeSection({
  times,
  title,
  callback,
  activeIndex,
  selectedTime,
  setSelectedTime,
}) {
  return (
    <div className="big-z width-100">
      <DropDown
        title={title}
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
      ></DropDown>
      <ButtonGrid
        times={times}
        callback={callback}
        activeIndex={activeIndex}
        show={selectedTime === title}
      ></ButtonGrid>
    </div>
  );
}
// const accordionClick = (e) => {
//   console.log('currentTarget', e.currentTarget);
//   e.currentTarget.lastChild.classList.toggle('accordion-closed');
//   e.currentTarget.lastChild.classList.toggle('opacity-zero');

//   e.currentTarget.firstChild.lastChild.classList.toggle(
//     'rotate-accordion-arrow'
//   );
// };
export default TimeSection;
