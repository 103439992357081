import React from 'react';

/**
 * Makes a button to navigate to the next page.
 *
 * @param {String} description the text for the button.
 * @param {Function} nextPageLink the callback function that handles navigation to next page.
 * @param {Boolean} isDisabled boolean to disable button.
 * @returns
 */
const NavigationButton = ({ description, nextPageLink, isDisabled }) => {
  let backgroundColor;
  if (isDisabled) {
    backgroundColor = 'inactive-bcg';
  } else {
    backgroundColor = 'accent-bcg';
  }
  return (
    <button
      disabled={isDisabled}
      type="submit"
      className={`flex-self-bottom short-long-padding ${backgroundColor} flex-column-self-center white-color width-85 clear-border bold normal-text`}
      onClick={nextPageLink}
    >
      {description}
    </button>
  );
};

export default NavigationButton;
