import React from "react";
import Divider from "./decorative/Divider";
import CircularImage from "./images/CircularImages";
import {ReactComponent as ExpandIcon } from '../assets/icons/chevron_right.svg';
import {ReactComponent as CloseIcon } from '../assets/icons/close.svg';
import {ReactComponent as CheckMark } from '../assets/icons/check-mark__list-item.svg';
import {ReactComponent as UncheckedMark } from '../assets/icons/unchecked-mark.svg';
import {ReactComponent as PeekIn } from '../assets/icons/peek-in_square.svg';
import LittleButton from "./buttons/LittleButton";
import { useMediaQuery } from "react-responsive";

/*
 * ListItemRaw Component
 * A foundational component that displays an image, title, description, and label.
 * Used by other ListItem components to render common content layout.
 */
const ListItemRaw = React.memo(
    ({
      title,
      description,
      label,
      imgSrc,
      imgSize,
      imgHeight,
      imgWidth,
      onSelect,
      onClick,
      onImageClick=onClick,
      headerTag: HeaderTag = 'h3',
      labelTag: LabelTag = 'h4',
      descBehavior = 'show',
      descTag: DescTag = 'p',
      addClasses
    }) => {
    const descriptionStyle = descBehavior === 'truncate'
        ? 'text__truncate'
        : ''; 
  
    return (
        <div className={`list-item--raw layout--split-item ${addClasses ? addClasses : ''}`}>

            {/* IMAGE */}
            { imgSrc && (
                <div className="hoverable list-item-image split-item__stubborn" onClick={onImageClick}> 
                    <CircularImage 
                        src={imgSrc} 
                        size={imgSize}
                        height={imgHeight}
                        width={imgWidth} /> 
                </div>
            )}
            
            {/* CONTENT */}
            <div className="list-item-content split-item__fill" onClick={onClick}>
                {/* header */}
                <HeaderTag>{title}</HeaderTag>
        
                {/* description */}
                {descBehavior !== 'hide' && (
                    <DescTag className={`${descriptionStyle}`}>{description}</DescTag>
                )}
        
                {/* label */}
                {label && <LabelTag className="label list-item-label">{label}</LabelTag>}
            </div>
        </div>
      );
    }
  );


 /*
 * ListItemMT Component
 * Specialized list item with expandable content. 
 * - Supports toggling expansion state via `isSelected` and `onToggle` props.
 * - Displays price with optional "starting at" text.
 * 
 * more info: https://www.notion.so/ListItem-Documentation-138449055c0f80919585f7ca1a42a39e?pvs=4#138449055c0f80b69a49ef73f52ed269
 */
export const ListItemMT = ({children, id, imgSrc, title, description, price, showStartingAtText=false, onToggle,  isSelected, headerTag="h3", labelTag="h4"}) => {

    const priceLabelTxt = (showStartingAtText ? "starting at " : '') + (price ? `$${price}` : '');
   
    return (
        <li className="list-item--mt align-content__vertical card">
            <div className="list-item align-content__horizontal" onClick={onToggle}>
                <ListItemRaw 
                    imgSrc={imgSrc}
                    title={title}
                    description={description}
                    label={!isSelected && priceLabelTxt}
                    headerTag={headerTag}
                    labelTag={labelTag}
                    descBehavior= {isSelected ? "show" : (children ? "truncate" : "show")}
                    addClasses=""
                />
                <div className="list-item-icon split-item__stubborn">
                    {isSelected ? <CloseIcon /> : <ExpandIcon /> }
                </div>
            </div>
            
        

            {/* EXPANDABLE SECTION */}
            <div className={`align-text__left list-item--mt-content ${isSelected ? 'list-item__grow' : 'list-item__shrink'}`}>
                <Divider />
                {children}
            </div>
        </li>
    )
}

/*
 * ListItemMD Component
 * Minimalistic list item for displaying price and selection state.
 * - Shows price label directly beneath title.
 * - Renders a CheckMark or UncheckedMark icon based on `isSelected`.
 * 
 * more info: https://www.notion.so/ListItem-Documentation-138449055c0f80919585f7ca1a42a39e?pvs=4#138449055c0f80838697f97c421bea4b
 */
export const ListItemMD = ({id, imgSrc, title, description, price, onSelected, isSelected, headerTag="h6"}) => {

    const priceLabelTxt = `$${price}`;
   
    return (
        <li className={`list-item--md card list-item align-content__vertical align-text__left align-content__horizontal ${isSelected ? 'list-item__selected' : ''}`} onClick={onSelected}>

                <ListItemRaw 
                    imgSrc={imgSrc}
                    imgSize={40}
                    title={title}
                    description={description}
                    label={priceLabelTxt}
                    headerTag={headerTag}
                    descBehavior= {"show"}
                    />
                <div className="list-item-icon">
                    {isSelected ? <CheckMark /> : <UncheckedMark /> }
                </div>
        </li>
    )
}

/*
 * ListItemAO Component
 * Advanced list item for detailed descriptions and adaptive UI.
 * - Expands to show detailed description with bullet points and small button.
 * - Selection and toggle behavior adjusts based on screen size.
 * - Shows dynamic icons and PeekIn button on hover.
 * 
 * more info: https://www.notion.so/ListItem-Documentation-138449055c0f80919585f7ca1a42a39e?pvs=4#138449055c0f8091a034db076a22df46
 */
export const ListItemAO = (
    {id,
    imgSrc,
    title,
    description,
    price,
    onSelected,
    onToggle,
    isSelected,
    isExpanded,
    descriptionBullets,
    additionalClasses,
    labelTag="h6",
    showSmallButton=true,
    showIcon=true
}) => {
   
    const priceLabelTxt = price ? `$${price}` : '';
    const isDesktop = useMediaQuery({ minWidth: 767 })

    const aSelected = (e) => {
        if(onToggle) {
            onSelected();
        }
        e.stopPropagation();
    }
    
    const aToggle = (e) => {
        if(onToggle) {
            !isDesktop ?
            onToggle()
            :aSelected(e);
        }
        e.stopPropagation();
    }
    
    const customDescription = (
        <div className={`${isSelected ? 'list-item--AO__selected' : ''} ${isExpanded ? 'list-item__grow' : 'list-item__shrink'}`}>
            <div className={`description ${isExpanded ? 'hoverable' : null}`} onClick={aToggle}>
                <p className="normal-text-small">{description}</p>
                <ul>
                {descriptionBullets && descriptionBullets.map((point, key) => {
                return(
                    <li key={`desc-bullet-${key}`} className={`${additionalClasses} list-item-bullet`} style={{listStyleType: 'disc'}}>
                        {point}
                    </li> 
                );
                
                })}
                </ul>
            </div>
            { showSmallButton && <LittleButton 
                isSelected={isSelected}
                onClick={(e)=>{
                    aSelected(e);
                    aToggle(e);
                }}
                activeText="add"
                selectedText="added"
                />
            }
        </div>
    );
   
    return (
        <li className={`${!isExpanded ? 'hoverable' : ''} list-item--AO list-item align-content__vertical align-text__left align-content__horizontal`} onClick={!isExpanded ? aToggle : null}>

            <ListItemRaw 
                imgSrc={imgSrc}
                imgSize={20}
                title={title}
                description={customDescription}
                label={!isExpanded && <div className='hoverable align-content__horizontal align-content__center label'>more info <PeekIn /></div>}
                labelTag={labelTag}
                descBehavior= {"show"}
                descTag={'div'}
                onClick={!isDesktop && isExpanded ? null : aToggle}
                onSelect={aSelected}
                onImageClick={isExpanded ? aSelected : aToggle}
                />
                
                { showIcon &&
                    <div className="list-item-icon align-content__horizontal" onClick={!isExpanded ? aSelected : aToggle}>
                        <h4 className="label" style={{paddingRight:'4px'}}>{priceLabelTxt}</h4>
                        <div className="split-item__stubborn hoverable">
                            {
                                isDesktop ? 
                                isSelected 
                                ? <CheckMark /> 
                                : <UncheckedMark />
                                :isExpanded ?
                                    <CloseIcon />
                                    : isSelected 
                                        ? <CheckMark /> 
                                        : <UncheckedMark />
                            }
                        </div>
                    </div>
                }
        </li>
    )
}

/**
 * Duration
 * Title
 * add-on list
 */

export const ListItemSO = (
    {id,
    imgSrc,
    title,
    // description,
    // price,
    // onSelected,
    // onToggle,
    // isSelected,
    isExpanded,
    // descriptionBullets,
    additionalClasses,
    labelTag="h6",
    showSmallButton=true,
    showIcon=false,
    specialData = null}
) => {

    const addOns = specialData[0];
    const duration = specialData[1][0].title;

    const modDesc = addOns ? `Add-ons: ${addOns.map(addOn => addOn.title).join(', ')}` : "";
    
    return(
        <ListItemAO
            id={id}
            imgSrc={imgSrc}
            title={title}
            description={modDesc}
            isExpanded={true}
            showSmallButton={false}
            showIcon={false}
            label={duration}
        />
    );
}