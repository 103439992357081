function ButtonGrid({ times, callback, activeIndex, show }) {
  let animationCSS = 'accordion-closed opacity-zero';
  if (show) animationCSS = '';
  return (
    <div
      className={`${animationCSS} bottom-margin-s button-grid  accordion-content grid-gap`}
    >
      {times.map((time, index) => {
        return (
          <Button
            key={time}
            name={time}
            onClick={callback}
            activeIndex={activeIndex}
          ></Button>
        );
      })}
    </div>
  );
}
function Button({ name, onClick, activeIndex }) {
  let css = '';
  if (activeIndex === name) {
    css = 'selected';
  }
  css += ' time-selection-button normal-text-small-bold';
  return (
    <button
      type="button"
      className={css}
      onClick={() => {
        onClick(name);
      }}
    >
      {name}
    </button>
  );
}

export default ButtonGrid;
