const LittleButton = ({
    children,
    onClick,
    isActive = true,
    isSelected,
    inactiveText = "Inactive",
    activeText = "Active",
    selectedText = "Selected",
  }) => {
    const getClassNames = () => {
      let classNames = "btn btn-small clear-border bold";
  
      if (isActive) classNames += " active";
      if (!isActive) classNames += " inactive";
      if (isSelected) classNames += " selected";
  
      return classNames;
    };
  
    return (
        <div className={getClassNames()} onClick={isActive ? onClick : null}>
        {children
          ? children
          : isActive
          ? isSelected
            ? selectedText
            : activeText
          : inactiveText}
      </div>
    );
  };
  
  export default LittleButton;
  