import React from 'react';
import { saveSensitiveUserData } from '../../utils/persistence/dataPersistence.js';
import { searchLocationId } from '../../api/fetchRequests/appointmentRequests.js';

export const SearchResults = ({
  results,
  setText,
  setResults,
  setSelected,
  setAddress,
  setErrors,
  display,
}) => {
  /**
   * Stores location data by getting formatted address from the google api to be used to
   * get zip,city and address data consistently.
   *
   * @param {INTEGER} id The google places id obtained from google
   */
  const storeLocation = async (id, mainText) => {
    // let arr = parseLocation(result);

    let locationResponse = await searchLocationId(id);

    if (locationResponse !== undefined) {
      let locationObject = {
        zip: locationResponse.postal_code,
        city: locationResponse.locality,
        addressLine1: locationResponse.street,
      };
      saveSensitiveUserData(JSON.stringify(locationObject), 'userAddress');
      setText(mainText);
      setResults([]);
      setSelected(true);
      setErrors((prevState) => {
        let newError = {};
        if (locationResponse.postal_code) newError.zip = undefined;
        if (locationResponse.locality) newError.city = undefined;
        if (mainText) newError.addressLine1 = undefined;
        newError.state = undefined;

        return {
          ...prevState,
          ...newError,
        };
      });
      setAddress((prevState) => {
        return {
          state: 'CA',
          country: 'United States',
          zip: locationResponse.postal_code || '',
          city: locationResponse.locality || '',
          addressLine1: mainText || '',
        };
      });
    }
    // TODO WHAT HAPPENS WHEN THE USER HITS A NON VALID ENTRY
    // FOR EXAMPLE IT DOESN'T HAVE TWO COMMA SEPARATED VALUES
    // if (typeof arr != 'string') {
    //   saveSensitiveUserData(arr[0], dataNames.STREET);
    //   saveSensitiveUserData(arr[1], dataNames.CITY);
    // }
  };

  const responseToDisplayResults = (autoCompleteGetResponse) => {
    let arr = [];
    if (autoCompleteGetResponse != null) {
      autoCompleteGetResponse.forEach((prediction) => {
        // arr.push(prediction.structured_formatting.main_text);
        arr.push({
          address: prediction.description,
          id: prediction.place_id,
          street: prediction.structured_formatting.main_text,
        });
      });
    }
    return arr;
  };
  let displayElement = '';
  if (!display) displayElement = 'display-none';

  let resultToShow = responseToDisplayResults(results);
  let list = resultToShow.map((res) => {
    return (
      <li
        className="search-result container-padding clear-border content-radius third-bcg relative-position z-index-10 "
        onMouseDown={(e) => {
          storeLocation(res.id, res.street);
        }}
        key={res.id}
        key-prop={res.id}
      >
        {res.address}
      </li>
    );
  });
  return (
    <ul
      className={`${displayElement} results container-bottom-radius overflow-hide absolute-position width-100`}
    >
      {list}
    </ul>
  );
};
