import React, { useEffect, useState } from 'react';
import axios from 'axios';
import RadioSelection from './RadioSelection.js';
import { saveUserSelection } from '../../utils/persistence/dataPersistence.js';
import { paymentURL } from '../../api/urls.js';

/**
 * Makes the payment selection section with radio buttons
 * in review page.
 * @param {Int} total the total cost of the appointment .
 * @param {Int} deposit the deposit amount for the appointment.
 * @param {String} appointmentDate the string representation of the appointment date.
 * @returns
 */
const PaymentOption = ({ total, deposit, appointmentDate }) => {
  const [paymentMethods, setPaymentOptionKeys] = useState(null);
  const [paymentSelection, setPaymentSelection] = useState('');
  const [paymentForToday, setPaymentForToday] = useState(total);

  useEffect(() => {
    // Fetch payment option keys from backend

    axios.get(`${paymentURL}/payment-options`)
      .then(response => {
        setPaymentOptionKeys(response.data);
        setPaymentSelection(response.data.default);
      })
      .catch(error => {
        console.error('Error fetching payment option keys:', error);
      });
  }, []);

  useEffect(() => {
    if (paymentMethods) {
      saveUserSelection(paymentSelection, paymentMethods.id);
    }
  }, [paymentSelection, paymentMethods]);

  if (!paymentMethods) {
    return <div>Loading...</div>;
  }

  return (
    <form id="option-form">
      <div className="flex-row-direction flex-space-between bottom-margin ">
        <h3>Your Payment Today</h3>
        <h2>${paymentForToday}</h2>
      </div>
      <RadioSelection
        firstLine="One time payment"
        secondLine={`$${total} due today`}
        setPaymentForToday={setPaymentForToday}
        setPaymentOption={setPaymentSelection}
        paymentOptionKey={paymentMethods.complete}
        paymentAmount={total}
        defaultOn={true}
        ></RadioSelection>

      <RadioSelection
        firstLine="Hold my spot"
        secondLine={`$${deposit} due today, $${
          total - deposit
        } due on ${appointmentDate}`}
        setPaymentForToday={setPaymentForToday}
        setPaymentOption={setPaymentSelection}
        paymentOptionKey={paymentMethods.partial}
        paymentAmount={deposit}
        defaultOn={false}
      ></RadioSelection>
    </form>
  );
};

export default PaymentOption;
