import { ReactComponent as RightArrow } from '../../assets/icons/chevron-right.svg';
function DropDown({ title, selectedTime, setSelectedTime }) {
  let rotateCSS = '';
  if (selectedTime === title) {
    rotateCSS = 'rotate-accordion-arrow';
  }
  return (
    <div
      className="accordion-header content-padding big-z"
      onClick={() =>
        selectedTime === title ? setSelectedTime('') : setSelectedTime(title)
      }
    >
      <h4 className="time-title unselective">{title}</h4>
      <RightArrow
        className={`${rotateCSS} unselective shifting-color`}
      ></RightArrow>
    </div>
  );
}
const accordionClick = (e) => {
  e.currentTarget.nextSibling.classList.toggle('accordion-closed');
  e.currentTarget.nextSibling.classList.toggle('opacity-zero');
  e.currentTarget.children[1].classList.toggle('rotate-accordion-arrow');
};
export default DropDown;
