import React, { useState, useEffect } from 'react';
import OptionSelectorPage from './OptionPageProvider';
import { groupOptionsByPath } from '../../utils/parsers/questionaireParser';

// TODO: Make this configurable
const defaultPath = '/';
const exitPath = '/set-your-place';

/**
 * Function to build and return an array of route components based on the
 * user's authentication status and the configuration file.
 *
 * @param {*} auth
 * @param {*} config
 * @returns
 */
const Questionnaire = ({ auth, config }) => {
  // Initialize activeTab with the current URL hash, defaulting to "/"
  const [activeTab, setActiveTab] = useState(
    window.location.hash || defaultPath
  );

  useEffect(() => {
    const handleHashChange = () => {
      const wHash = window.location.hash;
      if (wHash) setActiveTab(wHash);
      else setActiveTab(defaultPath);
    };

    // Add hashchange event listener
    window.addEventListener('hashchange', handleHashChange, false);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('hashchange', handleHashChange, false);
    };
  }, []);

  const handleNavigation = (label) => {
    window.location.hash = label.substring(1);
  };

  const groupedOptions = groupOptionsByPath(config);
  const optionRoutes = getOptionRoutes(groupedOptions, auth, handleNavigation);

  // Find the active page based on the activeTab state
  const activePage = optionRoutes.find(
    (page) =>
      page.path === activeTab ||
      `#${page.path.substring(1)}` === activeTab
  );

  // Render the active component or null if not found
  return activePage ? activePage.component : null;
};


/**
 * Function to get the next path from groupedOptions based on the current index
 *
 * @param {*} groupedOptions
 * @param {*} currentIndex
 * @returns
 */
const getNextPath = ({
  groupedOptions,
  currentIndex,
  isAuthenticated,
} = {}) => {
  if (!groupedOptions || currentIndex === undefined) {
    // If groupedOptions is not provided or currentIndex is undefined, return null.
    return null;
  }

  const totalRoutes = Object.keys(groupedOptions).length;

  if (currentIndex + 1 < totalRoutes) {
    const entries = Object.entries(groupedOptions);
    if (currentIndex + 1 < entries.length) {
      // Return the next path from groupedOptions
      return entries[currentIndex + 1][0];
    }
  }

  return exitPath;
};

/**
 * Constructs an array of public route objects as dictated by the config.json file.
 *
 * @param {Object} groupedOptions - Object with keys as paths and values as arrays of options.
 * @returns {Array} Array of route objects.
 *
 * EXAMPLE OUTPUT:
 * const publicRoutes = [
 *   { path: "{path}", component: <div>{option.name}</div>, exact: true },
 *   // the rest of the public routes...
 * ];
 */
const getOptionRoutes = (groupedOptions, isAuthenticated, handleNavigation) => {
  const routes = [];

  // Iterate over each path in groupedOptions
  Object.entries(groupedOptions).forEach((pathOption, index) => {
    const [path, options] = pathOption;

    const nextPathLink = getNextPath({
      groupedOptions: groupedOptions,
      currentIndex: index,
      isAuthenticated: isAuthenticated,
    });

    routes.push({
      path: path,
      component: (
        <OptionSelectorPage
          optionsParcel={options}
          linksTo={nextPathLink}
          onSubmit={handleNavigation}
          exitPath={exitPath}
        />
      ),
      exact: true,
    });
  });

  return routes;
};

export default Questionnaire;
