/**
 *
 * @param {String} dateTime String format YYYY-MM-DD HH:MI:SS
 * @returns {Int}  0-23 representing the hour
 */
export function parseDateTimeToHour(dateTime) {
  if (dateTime == null) {
    return null;
  }
  let split = dateTime.split(' ');
  let times = split[1].split(':');
  return parseInt(times[0]);
}
