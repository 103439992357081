import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  dataNames,
  saveSensitiveUserData,
} from '../utils/persistence/dataPersistence';
import CalendarForm from '../calendarComponents/CalendarForm';
import Therapist from '../calendarComponents/objects/Therapist';

import { reqDaysOffObject } from '../__tests__/RequestDaysOffOIbject';
import { snakeToCamel } from '../utils/parsers/objectParse.js';
import { parseTSRANGE, pstdayjs } from '../utils/calendar/calendarTools.js';
import { baseURL } from '../api/urls.js';

import CustomHero from '../components/common/CustomHero.jsx';
import NavBar from '../components/common/NavBar.jsx';
import ScheduleManager from '../calendarComponents/objects/ScheduleManager.js';
import { THERAPIST_ID } from '../environmentSetup.js';

const AppointmentDetails = ({ nextPath, prevPath }) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(nextPath);
  };
  const handlePrevPath = () => {
    navigate(prevPath);
  };
  const handleAppointmentSelection = async (dateObj) => {
    saveSensitiveUserData(dateObj, dataNames.DATE);
    handleNavigation();
  };

  const [therapist, setTherapist] = useState(null);
  useEffect(() => {
    Promise.all([
      fetch(`${baseURL}appointments/schedule`, { mode: 'cors' }),
      fetch(`${baseURL}availability?id=1`, { mode: 'cors' }),
      fetch(`${baseURL}availability/getVisible?id=${THERAPIST_ID}`, {
        mode: 'cors',
      }),
    ])
      .then((response) => {
        return response;
      })
      .then(async ([dbAppointments, dbTherapistAvailability, allVisible]) => {
        let resolvedAppointments = await dbAppointments.json();
        let resolvedAvailability = await dbTherapistAvailability.json();
        let [obj] = resolvedAvailability;
        let allVisibleData = await allVisible.json();
        let scheduleManager = new ScheduleManager();
        allVisibleData.forEach((data) =>
          scheduleManager.addSchedule(snakeToCamel(parseTSRANGE(data)), [])
        );
        console.log(resolvedAppointments);
        scheduleManager.mapBookedAppointmentsToSchedules(
          snakeToCamel(resolvedAppointments)
        );
        // console.log(snakeToCamel(parseTSRANGE(allVisibleData[0])));
        // console.log(scheduleManager.getSoonestAvailableWorkday(pstdayjs()));
        let tempTherapist = new Therapist(
          'Hector',
          reqDaysOffObject,
          snakeToCamel(parseTSRANGE(obj)),
          snakeToCamel(resolvedAppointments)
        );

        setTherapist(scheduleManager);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="page-layout__stacked">
      <NavBar callback={handlePrevPath}></NavBar>

      <div className="height-100 flex-column-direction">
        <CustomHero
          firstLine="Schedule Your"
          secondLine="Session"
          subheading="Find a moment that fits your schedule."
        ></CustomHero>
        {therapist ? (
          <>
            <CalendarForm
              therapist={therapist}
              handleRoute={handleAppointmentSelection}
            ></CalendarForm>

            {/* <button onClick={handleNavigation}>Go To Deposit</button> */}
          </>
        ) : (
          <h1>waiting </h1>
        )}
        {/* <div className="white-color" onClick={handleNavigation}>
        bypass
      </div> */}
      </div>
    </div>
  );
};

export default AppointmentDetails;
