export const objectToQueryString = (obj) => {
    const flatten = (obj, prefix = '') => {
        return Object.keys(obj).reduce((acc, key) => {
            const pre = prefix.length ? `${prefix}[${key}]` : key;
            const value = obj[key];
            
            if (Array.isArray(value)) {
                value.forEach((v, i) => {
                    acc.push(`${pre}[${i}]=${encodeURIComponent(v)}`);
                });
            } else if (typeof value === 'object' && value !== null) {
                acc.push(...flatten(value, pre));
            } else {
                acc.push(`${pre}=${encodeURIComponent(value)}`);
            }
            
            return acc;
        }, []);
    };
    
    return flatten(obj).join('&');
}