import React, { useState } from 'react';

/**
 * Makes the hero section of each page with their respective subheadings.
 *
 * @param {String} firstLine The first line that will be colored blue
 * @param {String} secondLine the second line of the header that will be white
 * @param {String} subheading the subheading for the main heading
 * @returns
 */
const CustomHero = ({ firstLine, secondLine, subheading }) => {
  return (
    <header className="page-hero flex-column-direction text-align-center flex-column-align-items-center">
      <h1>
        <span className="text-blue-accent">{firstLine}</span>
        <br></br>
        {secondLine}
      </h1>
      <p className="subheading">{subheading}</p>
    </header>
  );
};

export default CustomHero;
