import React, { useEffect } from 'react';
import { autoCompleteAPIGet } from '../../api/fetchRequests/appointmentRequests.js';

export const SearchBar = ({
  results,
  setResults,
  setText,
  text,
  selected,
  setSelected,
  setErrors,
  hasError,
  setDisplay,
  displayResults,
  setAddress,
}) => {
  /**
   * This changes the search field text per key stroke and updates
   * the selected state to false.
   *
   * @param {Event} e search field event
   */
  const onChange = (e) => {
    let newText = e.target.value;
    if (newText === '') setResults('');
    setText(newText);
    setSelected(false);
    setErrors((prevState) => ({
      ...prevState,
      addressLine1: undefined,
    }));
    setAddress((prevState) => ({
      ...prevState,
      addressLine1: newText,
    }));
  };
  /**
   * Returns a list of addresses given {input:string} object that will be passed to
   * google autocomplete POST api.
   *
   * @param {Object} postBody Object containing a string to search with google api
   * @returns
   */
  // const autoComplete = async (postBody) => {
  //   let results = await autoCompleteAPI(postBody);
  //   let arr = [];

  //   if (results.suggestions != null) {
  //     results.suggestions.forEach((suggestion) => {
  //       arr.push({
  //         address: suggestion.placePrediction.text.text,
  //         id: suggestion.placePrediction.placeId,
  //       });
  //     });
  //   }
  //   return arr;
  // };
  /**
   * Sets the result state to a list of addresses obtained by the google api
   * from the input "e".
   *
   * @param {String} e the string that will be used by google to search for addresses
   */
  // const searchLocation = async (e) => {
  //   let results = await searchLocationAPI(e);
  //   if (Object.keys(results).length > 0) {
  //     let arr = results.places.map((obj) => {
  //       return { address: obj.formattedAddress, id: obj.id };
  //     });
  //     setResults(arr);
  //   }
  // };

  /**
   * Returns a list of addresses given {input:string} object that will be passed to
   * google autocomplete GET api.
   *
   * NOTE: the difference between this method and the one below is the google route. This
   * one uses a GET request while the other one uses a POST request. Results given vary by route.
   *
   * @param {Object} input Object containing a string to search with google api GET autocomplete
   * @returns
   */
  const autoCompleteGet = async (input) => {
    let results = await autoCompleteAPIGet(input);
    let answers = await results.json();

    return answers.predictions;
  };

  useEffect(() => {
    if (text !== '' && !selected) {
      autoCompleteGet(text).then((res) => {
        setResults(res);
      });

      // autoComplete(postBody).then((res) => {
      //   setResults(res);
      // });
    }
  }, [text]);

  let bottomBorder = 'container-bottom-radius';
  let redBorder = '';
  let textColor = 'text-gray';
  if (hasError) {
    redBorder = 'error-border';
    textColor = 'text-clr-error';
  }
  if (results.length > 0 && text !== '' && text.length < 10 && displayResults)
    bottomBorder = '';
  return (
    <div className="animated-textfield-container">
      <input
        name="address-search-bar"
        autoComplete="off"
        id="address-search-bar"
        placeholder=" "
        value={text}
        onChange={onChange}
        onBlur={(e) => {
          setDisplay(false);
        }}
        onFocus={() => setDisplay(true)}
        className={`${redBorder} container-padding  normal-text-big clear-border container-top-radius  ${bottomBorder} animated-textfield-input`}
      ></input>
      <label
        htmlFor="address-search-bar"
        className={`${textColor} animated-textfield-label`}
        placeholder="address1"
      >
        Street
      </label>
      {/* <button onClick={(e) => searchLocation(text)}>search</button> */}
      {hasError && (
        <p className="capitalize text-clr-error normal-text-light">
          *address required
        </p>
      )}
    </div>
  );
};
