import React from 'react';
import Disclaimers from '../Disclaimers';
import AddOnList from '../AddOnList';


const Template = ({ optionTemplate, option, onSubmit }) => {
    switch(optionTemplate) {
        case 'AddOnList':
            return (<AddOnList 
                    option={option}
                    onSubmit={onSubmit}
                    />);
        
        case 'Disclaimers':
            return (<Disclaimers 
                    option={option}
                    onSubmit={onSubmit}
                    />);

        default:
            return (<div>error...</div>);
    }
};

const SingleSelector = ({ option, executeNavigation }) => {
  
    return (
        <Template 
            optionTemplate={option.settings.template} 
            option={option}
            onSubmit={executeNavigation}
            />
    );
}

export default SingleSelector;
