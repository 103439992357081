import React, { useState } from 'react';

/**
 * Makes the radio button and label section in review page.
 * @param {String} firstLine text for payment title in bold.
 * @param {String} secondLine text for payment describing payment.
 * @param {Function} setPaymentForToday sets the payment amount state .
 * @param {Int} paymentAmount the payment amount for this option.
 * @param {Boolean} defaultOn sets the default radio button.
 *
 *
 * @returns
 */
const RadioSelection = ({
  firstLine,
  secondLine,
  setPaymentForToday,
  setPaymentOption,
  paymentOptionKey,
  paymentAmount,
  defaultOn,
}) => {
  return (
    <div className="flex-row-direction flex-space-between bottom-margin">
      <label htmlFor={paymentAmount}>
        <h6>{firstLine}</h6>
        <p className="normal-text-light width-85">{secondLine}</p>
      </label>
      <input
        onClick={(e) => {
          setPaymentForToday(paymentAmount);
          setPaymentOption(paymentOptionKey);
        }}
        id={paymentAmount}
        type="radio"
        name="payment-option"
        defaultChecked={defaultOn}
      ></input>
    </div>
  );
};

export default RadioSelection;
