import React from 'react';

const Details = ({ date, time, location }) => {
  return (
    <div className="flex-column-direction flex-column-container-gap">
      <h3>Your Appointment Time</h3>
      <DetailsSection firstLine="Day" secondLine={date}></DetailsSection>
      <DetailsSection firstLine="Time" secondLine={time}></DetailsSection>
      <div>
        <h6>Location</h6>
        <p className="normal-text-big">
          {location.addressLine1} <br></br> {location.city}, {location.state}{' '}
          {location.zip}
        </p>
      </div>
    </div>
  );
};
const DetailsSection = ({ firstLine, secondLine }) => {
  return (
    <div>
      <h6>{firstLine}</h6>
      <p className="normal-text-big">{secondLine}</p>
    </div>
  );
};
export default Details;
