import React from 'react';
import './Theme/App.css';
import './Theme/EMTTheme.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Questionnaire from './routes/Questionaire/routeSetter';
import useOptions from './utils/questionaireLoader';
import AppointmentDetails from './routes/AppointmentDetails';
import CollectDeposit from './routes/DepositCollection';
import ConfirmationRouter from './routes/FinalConfirmation';
import ServiceOverview from './routes/ServiceAgreement';
import LocationDetails from './routes/LocationDetails.js';
import ContactForm from './routes/meta/ContactUs';

const route1 = '/';
const route2a = '/set-your-place';
const route2b = '/set-your-time';
const route3 = '/waives-docs';
const route4 = '/make-it-official';
const route5 = '/confirmation';

const contactPage = '/contact-us';

const App = () => {
  console.log('EMT: 0.2.1');
  // Using custom hook to load configuration data
  const { config, loading, error } = useOptions();

  // Authentication state (currently hardcoded to false)
  const isAuthenticated = false;

  // Show a loading message while the configuration is being loaded
  if (loading) {
    return <div>Loading...</div>;
  }

  // Show an error message if there was an error loading the configuration
  if (error) {
    return <div>Fatal error: {error.message}</div>;
  }

  // Main app content
  return (
    <Router basename="/">
      <Routes>
        <Route
          path={route1}
          element={
            <Questionnaire
              auth={isAuthenticated}
              config={config}
              nextPath={route2a}
            />
          }
        />
        <Route
          path={route2a}
          element={<LocationDetails nextPath={route2b} prevPath={route1} />}
        />
        <Route
          path={route2b}
          element={<AppointmentDetails nextPath={route3} prevPath={route2a} />}
        />
        <Route
          path={route3}
          element={<ServiceOverview nextPath={route4} prevPath={route2b} />}
        />
        <Route path={route4} element={<CollectDeposit nextPath={route5} />} />

        <Route
          path={route5}
          element={<ConfirmationRouter nextPath={route1} />}
        />

        <Route path={contactPage} element={<ContactForm />} />
      </Routes>
    </Router>
  );
};

export default App;
