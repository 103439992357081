import React from 'react';
import NestedSelector from '../NestedSelections';

const Template = ({ optionTemplate, onSubmit, options }) => {
    switch(optionTemplate) {
        case 'NestedSelector':
            return (<NestedSelector 
                        options={options}
                        onSubmit={onSubmit}
                        />);
        
        default:
            return (<div>error...</div>);
    }
};

const DoubleSelector = ({ options, executeNavigation }) => {

    // TODO: Read options settings to provide appropriate template 

    return (
        <Template 
            optionTemplate={'NestedSelector'}
            options={options}
            onSubmit={executeNavigation}
        />
    );
};

export default DoubleSelector;