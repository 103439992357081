// REFERENCE DOCUMENTAION --> https://symanity.atlassian.net/wiki/x/AYBSAw 

/**
 * Serves as an intermediary for handling option objects. Ensures consistency
 * in option object structure throughout the application, regardless of changes
 * in the configuration file format.
 * 
 * @param {Object|Array} optionParcel - The option object or array of option objects as defined in the configuration file.
 * @returns {Object|Array} A consistent option object structure or an array of such structures for use in the application.
 * @throws {Error} Throws an error if optionParcel is undefined.
 */
export const openOptionsParcel = (optionParcel) => {
  if (!optionParcel) {
    throw new Error("optionParcel is undefined");
  }
  else if(Array.isArray(optionParcel)) {
    return optionParcel.map(item => defineOption(item));
  }
  else {
    return defineOption(optionParcel);
  }
};

/**
 * [Deprecated]
 * Helper function to process and define a single option object.
 * This function can be adjusted to define the structure of the option object
 * as per the application's requirements.
 * 
 * @param {Object} option - The single option object.
 * @returns {Object} The processed and structured option object.
 */
const defineOption = (option) => {
  return option;
};

// Function to get options based on a specific criteria, e.g., by ID
export const getOptions = (config, searchById) => {
  if (!config || !config.Options) return [];
  return config.Options.filter(option => option.id === searchById);
};


/**
 * Retrieves all the option IDs from the configuration file.
 * 
 * @param {Object} configFile - The configuration object containing the options.
 * @returns {Array<string>} An array of all option IDs from the configuration.
 */
export const getAllOptionIds = (configFile) => {
  if (!configFile || !configFile.Options) return [];

  // Map over the Options array and return their IDs
  return configFile.Options.map(option => option.id);
};


/**
 * Groups options from the configuration data based on their specified path paths.
 * This function iterates through the 'Options' array in the configuration object,
 * and organizes them into a map where each key represents a unique path path 
 * defined in the 'settings' of each option, and the corresponding value is an 
 * array of options that belong to that path.
 * 
 * @param {Object} config - The configuration object containing the options.
 * @returns {Object} A map where keys are path paths (strings) and values are 
 *                   arrays of options (objects) associated with those paths.
 *                   Returns an empty object if 'config' or 'config.Options' is 
 *                   not provided or is invalid.
 */
export const groupOptionsByPath = (config) => {
  if (!config || !config.Options) return [];

  const groupedOptions = {};
  
  if (config && config.Options) {
    config.Options.forEach(option => {
      const path = option.settings['path-name'];

      if (!groupedOptions[path]) {
        groupedOptions[path] = [];
      }

      groupedOptions[path].push(option);
    });
  }

  return groupedOptions;
};

export const getTotalPrice = () => {

}
