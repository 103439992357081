const CircularImage = ({ src, alt, height, width, size}) => {
    const style = {
        borderRadius: '50%',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const imgStyle = {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    };

    return (
        <div className="image-wrapper" style={style}>
            <img src={src} alt={alt} style={imgStyle} />
        </div>
    );
};

export default CircularImage;
