import { objectToQueryString } from './objectToQueryString';
import { questionaireURL } from '../../api/urls';

/**
 * Returns 60 | 90 | 120 integer based on value passed in. Value should come in from
 * session storage.
 *
 * @param {Array[String]} data The array representing massage_duration from session storage
 * @returns {INTEGER}   60 | 90 | 120
 */
export const mapAppointmentLength = async (data) => {
  try {
    const duration = await getServiceDuration(data);
    // console.log('Duration is:', duration);
    return duration;
  } catch (error) {
    console.error('Error mapping appointment length:', error);
    throw error;
  }
};

const getServiceDuration = async (userSelections) => {
  try {
    const queryString = objectToQueryString(userSelections);
    const response = await fetch(
      `${questionaireURL}/selection-duration?${queryString}`
    );
    const responseText = await response.text();
    const responseData = JSON.parse(responseText);

    // console.log("DURATION LOADED FROM BACKEND: ", responseData.serviceDuration);
    return responseData.serviceDuration;
  } catch (error) {
    console.error('Error fetching service duration:', error);
    return 0;
  }
};
