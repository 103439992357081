import React, { useState } from 'react';
import NavBar from '../../components/common/NavBar.jsx';
import CustomHero from '../../components/common/CustomHero.jsx';
import LocationTextField from '../../components/inputFields/LocationTextField.jsx';
import NavigationButton from '../../components/common/NavigationButton.jsx';
import { emailURL } from '../../api/urls.js';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    privacyPolicyAccepted: false,
  });
  const [errors, setErrors] = useState({});

  const handleChangeCheckBox = (e) => {
    const { name, value, type, checked } = e.target;
    console.log('Vaue', name);
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleChange = (e, locationType) => {
    const { name, value, type, checked } = e.target;

    e.currentTarget.classList.remove('text-clr-error', 'error-border');

    e.currentTarget.classList.remove('error-border');

    if (e.currentTarget.nextSibling)
      e.currentTarget.nextSibling.classList.remove('text-clr-error');
    setErrors((prevState) => ({
      ...prevState,
      [locationType]: undefined,
    }));

    setFormData((prevState) => ({
      ...prevState,
      [locationType]: value,
    }));
  };

  const handleValidation = () => {
    let tempErrors = {};
    let isValid = true;

    if (!formData.name) {
      tempErrors['name'] = 'Name is required';
      isValid = false;
    }
    if (!formData.email) {
      tempErrors['email'] = 'Email is required';
      isValid = false;
    }
    if (!formData.subject) {
      tempErrors['subject'] = 'Subject is required';
      isValid = false;
    }
    if (!formData.message) {
      tempErrors['message'] = 'Message is required';
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.privacyPolicyAccepted || !handleValidation()) {
      // alert('You must accept the privacy policy before submitting.');
      return;
    }
    console.log('Form Data Submitted:', formData);
    fetch(`${emailURL}/contact`, {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-type': 'application/json',
      },
      mode: 'cors',
    });
  };

  let redBorder = '';
  let textColor = 'text-gray';
  if (errors.message) {
    redBorder = 'error-border';
    textColor = 'text-clr-error';
  }
  return (
    <>
      <NavBar></NavBar>
      <div className="standard-max-width center-margin">
        <CustomHero
          firstLine={'Contact'}
          subheading="We’ll review your information and contact you with recommendations to ensure your safety and comfort."
        ></CustomHero>
        <form onSubmit={handleSubmit}>
          <div className="container-padding secondary-bcg container-radius flex-column-direction flex-column-container-gap">
            <LocationTextField
              placeHolder="name"
              onChange={handleChange}
              hasError={errors.name !== undefined}
            ></LocationTextField>
            <LocationTextField
              placeHolder="email"
              onChange={handleChange}
              hasError={errors.email !== undefined}
            ></LocationTextField>
            <LocationTextField
              placeHolder="subject"
              onChange={handleChange}
              hasError={errors.subject !== undefined}
            />
            <div id="additional-notes-container" className="relative-position">
              <textarea
                id="message-us"
                className={`width-100 animated-textarea-input ${redBorder} ${textColor} container-padding container-radius clear-border normal-text-big `}
                placeholder=""
                onChange={(e) => {
                  handleChange(e, 'message');
                }}
                value={formData.message}
                name="message"
              ></textarea>
              <label
                htmlFor="message-us"
                className={`absolute-position container-padding animated-textarea-label ${textColor}`}
              >
                Message Us
              </label>
              {errors.message !== undefined && (
                <p className="capitalize text-clr-error normal-text-light">
                  *Message required
                </p>
              )}
            </div>

            <div className="flex-row-direction flex-column-container-gap">
              <input
                type="checkbox"
                name="privacyPolicyAccepted"
                checked={formData.privacyPolicyAccepted}
                onChange={handleChangeCheckBox}
                required
              />
              <label>
                By submitting, you agree to our{' '}
                <a
                  href="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </label>
            </div>
          </div>

          <section className="section-nav text-align-center">
            <NavigationButton description={`Submit`} />
          </section>
        </form>
      </div>
    </>
  );
};

// const formStyle = {
//   maxWidth: '400px',
//   margin: '0 auto',
//   padding: '20px',
// };

const privacyPolicyStyle = {
  display: 'flex',
  alignItems: 'center',
  margin: '10px 0',
};

const buttonStyle = {
  width: '100%',
  padding: '10px',
  backgroundColor: '#007bff',
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
};

export default ContactForm;
